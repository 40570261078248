


































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import AlButton from "@/components/al/Button.vue";
import RegistTypeDialog from "@/components/sign/RegistTypeDialog.vue";

@Component({
    components: {
        "el-dropdown": Dropdown,
        "el-dropdown-menu": DropdownMenu,
        "el-dropdown-item": DropdownItem,
        "al-button": AlButton,
        RegistTypeDialog,
    },
})
export default class Navbar extends Vue {
    @Getter("getToken") token: any;
    @Action("getSetToken") setToken: any;
    @Getter("getLang") lang: any;
    @Action("getSetLang") setLangCommit: any;
    @Getter("getUser") getUser: any; //获取store中的user的方法
    @Getter("getAssessPermission") assessPermission: any; //获取store中的assessPermission
    @Action("getSetAssessPermission") setAssessPermission: any; //设置store评审权限
    @Action("getSetUser") setUser: any;

    title = "全球产业创新创先技术百强榜单";
    loginButton: any = true; //未登录状态下是否显示登录按钮
    registButton: any = true; //未登录状态下是否显示注册按钮
    dropdownStyleTop: any = ""; //下来菜单top

    registDialog: boolean = false; //是否显示注册种类选择弹窗

    showAssess: boolean = false; //下来菜单是否显示评审

    get user() {
        if (this.token) return this.getUser();
        else return {};
    }

    /* 修改语言 */
    changeLang() {
        if (this.lang == "zh") {
            sessionStorage.setItem("lang", "en");
            this.setLangCommit("en");
        } else if (this.lang == "en") {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit("zh");
        } else {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit("zh");
        }
        let params = JSON.parse(JSON.stringify(this.$route.params));
        let name = JSON.parse(JSON.stringify(this.$route.name));
        params.lang = this.lang;
        // this.$router.push({ name: name, params: params });

        // console.log(`当前语言为：${this.lang}`);
    }

    /* 修改语言按钮的文本 */
    langmsg() {
        if (this.lang == "zh") {
            return "Eng";
        } else if (this.lang == "en") {
            return "中";
        } else {
            return "中";
        }
    }

    goSubPage() {
        this.$router.push({
            name: "login",
        });
    }
    // 去注册
    goRegisterPage() {
        this.$router.push({
            name: "register-plain",
        });
        // this.registDialog = true; //打开专家与项目注册选择弹窗
    }

    /**登出 */
    logout() {
        //注销
        sessionStorage.clear();
        this.setToken("");
        this.setAssessPermission([]);
        this.setUser({});
        this.$router.push({ name: "home" });
    }

    goUserHome(name?: any) {
        if (name) {
            if (name == "register") {
                this.$router.push({
                    name: name,
                    params: { step: "1" },
                });
            } else {
                this.$router.push({ name: name });
            }
        } else {
            this.$router.push({ path: "/" });
        }
    }

    // 点击注册按钮下拉菜单项
    handleRegistDropdownCommand(command: any) {
        if (command == "projectRegist") {
            this.$router.push({
                name: "register",
                params: { step: "1" },
            });
        } else {
            this.$router.push({
                name: "register-expert",
                params: { step: "1" },
            });
        }
    }

    //点击个人中心下拉菜单项
    handleUserDropdownCommand(command: any) {
        if (command == "logout") {
            this.logout();
        } else if (command == "assessList") {
            this.$router.push({
                name: command,
                params: {
                    eid: this.user.expert_id,
                    id: this.assessPermission[0].review_id,
                },
            });
        } else {
            this.goUserHome(command);
        }
    }

    @Watch("$route")
    routeChange(to: any, from: any) {
        // console.log(from)
        if (to.name == "login") {
            this.loginButton = false;
            this.registButton = true;
        } else if (to.name == "register") {
            this.loginButton = true;
            this.registButton = true;
        } else {
            this.loginButton = true;
            this.registButton = true;
        }
    }

    @Watch("user")
    userChange(nd: any, od: any) {
        this.getAssessPermission();
    }

    /**获取评审权限 */
    getAssessPermission() {
        const eid = this.user.expert_id;
        if (eid) {
            if (!this.assessPermission || this.assessPermission.length <= 0) {
                const getOpt = this.$api.apiList.getAssessPermission;
                const opt = getOpt(eid);
                this.$axios({
                    url: opt.url,
                    method: opt.method,
                    headers: opt.headers,
                })
                    .then((res: any) => {
                        this.setAssessPermission(res.data.data);
                        if (res.data.data.length > 0) {
                            this.showAssess = true;
                        } else {
                            this.showAssess = false;
                        }
                    })
                    .catch((err: any) => {
                        console.log(err);
                        this.$notify({
                            title: "警告",
                            message: "获取评审权限失败",
                            type: "warning",
                        });
                    });
            } else {
                this.showAssess = true;
            }
        } else {
            this.showAssess = false;
        }
    }

    mounted() {
        this.langmsg();
        if (this.$route.name == "login") {
            this.registButton = true;
            this.loginButton = false;
        } else if (this.$route.name == "register") {
            this.registButton = true;
            this.loginButton = true;
        } else {
            this.loginButton = true;
            this.registButton = true;
        }
        this.getAssessPermission();
    }
}
